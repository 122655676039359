import { graphql, useStaticQuery } from 'gatsby';

export const useLogoEdge = () => {
  return useStaticQuery(
    graphql`
      {
        file(relativePath: { eq: "logo_edge.png" }) {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              transformOptions: { fit: FILL }
              width: 175
            )
          }
        }
      }
    `
  );
};

export default useLogoEdge;
