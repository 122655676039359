import * as React from 'react';

import WebExtensionAddonPage from '../ui/pages/WebExtensionAddonPage';

// markup
const Page = () => {
  return <WebExtensionAddonPage />;
};

export default Page;
