import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { display } from '@mui/system';

interface SupportedBrowserProps {
  name: string;
  image: any;
  storeUrl: string;
}

const SupportedBrowser = ({ name, image, storeUrl }) => {
  const handleOnStoreUrl = () => {
    window.open(storeUrl);
  };

  return (
    <Card sx={{ maxWidth: 400 }}>
      {/* <CardMedia
        component='img'
        alt='green iguana'
        height='250'
        image={image}
      /> */}
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <GatsbyImage image={getImage(image.file)} alt={name} />
      </Box>
      <CardContent>
        <Typography gutterBottom variant='h5' component='div'>
          {name}
        </Typography>
        <Typography variant='body2' color='text.secondary'>
          {`Install the Magic Audio ${name} Extension from the ${name} Store.`}
        </Typography>
      </CardContent>
      <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant='contained'
          color='secondary'
          size='large'
          onClick={handleOnStoreUrl}
        >{`Get the ${name} extension`}</Button>
      </CardActions>
    </Card>
  );
};

export default SupportedBrowser;
