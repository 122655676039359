import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import DefaultLayout from '../../layouts/DefaultLayout';
import Seo from '../../../app/Seo';
import Page from '../../components/Page';
import SupportedBrowser from './SupportedBrowser';
import useLogoChrome from './useLogoChrome';
import useLogoEdge from './useLogoEdge';

import useCampaignBuilder from '../../../hooks/useCampaignBuilder';
// import ListenToRealTimeDemo from "../../components/ListenToRealTimeDemo";

export default function WebExtensionAddonPage() {
  const logoChrome = useLogoChrome();
  const logoEdge = useLogoEdge();

  const chromeExtensionUrl = useCampaignBuilder({
    url: 'https://chrome.google.com/webstore/detail/magic-audio/lmogpoignkgofmackdghknhpdgfkocef',
    source: 'website',
    medium: 'button',
    campaignId: 'Chrome Extension',
  });

  // console.log(`chromeExtensionUrl`, chromeExtensionUrl);

  const supportedBrowsers = [
    {
      name: 'Chrome',
      image: logoChrome,
      storeUrl: chromeExtensionUrl,
    },
    {
      name: 'Edge',
      image: logoEdge,
      storeUrl:
        'https://microsoftedge.microsoft.com/addons/detail/dhhmadpanhlgalnlopcoklmacbhfebgp',
    },
  ];

  return (
    <>
      <Seo title='Web Extension Addon' />
      <DefaultLayout>
        <Page pageTitle='Web extension / addon'>
          <Grid
            container
            direction='row'
            spacing={3}
            columnSpacing={{ xs: 5, sm: 5, md: 3 }}
            justifyContent='center'
            alignItems='center'
            align='center'
            justify='center'
          >
            {supportedBrowsers.map((item) => {
              return (
                <Grid item xs={12} md={6} key={item.name}>
                  {/* <h1>item</h1> */}
                  <SupportedBrowser
                    key={item.name}
                    name={item.name}
                    image={item.image}
                    storeUrl={item.storeUrl}
                  />
                </Grid>
              );
            })}
          </Grid>
          {/*<ListenToRealTimeDemo/>*/}
          <Typography
            variant='h6'
            align='center'
            gutterBottom={true}
            sx={{ p: 0, mt: 4 }}
          >
            * All trademarks are the property of their respective owners
          </Typography>
        </Page>
      </DefaultLayout>
    </>
  );
}
